<template>
  <div>
    <h3 v-if="week_visits > 0" style="text-align: center; margin: auto;">Avance Quincenal</h3>
    <p v-if="week_visits > 0" style="text-align: center;">
      {{done_visits}} de {{week_visits}} visitas [ {{week_visits > 0 ? Math.round(done_visits / week_visits * 100) : 0}}% ]<br />
      <br />
    </p>
    <span v-if="page == 'visits' && false" style="float: right; padding-left: 5px;"><CButton color="dark" size="sm" @click="outfit(branch.branch_id)">Outfit</CButton></span>
    <span v-if="page == 'visits'" style="float: right;"><CButton color="danger" size="sm" @click="branchs && branchs.length > 0 ? backToBranchs() : showBranchs()">Regresar</CButton></span>
    <span v-if="page == 'visit' && (current_visit.checkout == null || current_visit.checkout == '')" style="float: right; padding-left: 5px;"><CButton color="dark" size="sm" @click="checkout(current_visit.id)">Terminar Visita</CButton></span>
    <span v-if="page == 'visit' && !(current_visit.checkout == null || current_visit.checkout == '')" style="float: right;"><CButton color="danger" size="sm" @click="visits && visits.length > 0 ? backToVisits() : showVisit(current_visit.branch_id)">Regresar</CButton></span>
    <span v-if="page == 'branchs' && total_branchs >= 0">Tienes {{total_branchs}} sucursales asignadas.</span>
    <span v-if="page == 'visits' && total_visits >= 0">Tienes {{total_visits}} <span v-if="total_visits == 1">marca (visita)</span><span v-if="total_visits != 1">marcas (visitas)</span> en esta sucursal.</span>
    
    <span v-if="page == 'visit' && current_visit">
      <b>{{current_visit.store_name}}</b><br />
      {{current_visit.branch_name}}
    </span>
    <br /><br /><br />
    <template v-if="page == 'branchs'">
      <div v-for="branch in branchs" :key="'branch_'+branch.id">
        <CRow>
          <CCol sm="12" md="6">
            <CCard>
              <CCardHeader>
                <div class="card-header-actions">
                    <CButton v-show="!branch.checkin" color="info" size="sm" @click="checkin(branch.branch_id)">Check In</CButton>
                    <CButton v-show="branch.checkin" color="success" size="sm" @click="showVisit(branch.branch_id)">Ver Visita</CButton>
                </div>
                <b>{{branch.store_name}}</b><br />
                {{branch.branch_name}}<br />                
              </CCardHeader>
              <CCardBody>
                <a style="font-size: 12px;" :href="'https://maps.google.com/?q='+branch.branch_address+'&z=18&t=k'" target="_blank">
                  <CIcon :height="15" name="cil-location-pin"/> {{branch.branch_address}}
                </a>
                <br /><br />
                <span style="float: right; font-size: 12px;">{{branch.checkout ? 'TERMINADA' : 'PENDIENTE'}}</span>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-if="page == 'visits'">
      <div v-for="visit in visits" :key="'branch_'+visit.id">
        <CRow>
          <CCol sm="12" md="6">
            <CCard>
              <CCardHeader>
                <div class="card-header-actions" style="font-size: 12px;">
                    <CButton color="info" size="sm" @click="startVisit(visit.id)">Fotos e Inventarios</CButton>
                </div>
                <span style="font-size: 12px;">
                  <b>{{visit.customer_name}}</b><br />
                  {{visit.branch.store_name}} - {{visit.branch.branch_name}}<br />
                </span>
              </CCardHeader>
              <CCardBody>
                <CButton v-show="visit.np > 0 && visit.products.length == 0" color="success" size="sm" @click="showProducts(visit)" style="float: right;">Ver Productos</CButton>
                <div v-if="visit.products != undefined && visit.products.length == 0" style="font-size: 12px;">ESTA MARCA TIENE {{visit.np}} PRODUCTOS</div>
                <div v-for="product in visit.products" style="font-size: 12px;" :key="'product_id'+product.id">
                  <ul>
                    <li>
                      {{product.name}}
                    </li>
                  </ul>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </template>
    <div v-show="page == 'visit'">
      <CRow>
        <CCol sm="12" md="6">
          <CCard>
            <CCardHeader color="info">
              <span style="font-size: 12px;">
                <b>REPORTE DE LA VISITA</b>
              </span>
            </CCardHeader>
            <CCardBody>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol style="text-align: center; font-size: 12px;">
                  Antes
                </CCol>
                <CCol style="text-align: center; font-size: 12px;">
                  Después
                </CCol>
                <CCol style="text-align: center; font-size: 12px; margin-bottom: 10px;">
                  Evidencia
                </CCol>
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol>
                  <form id="upload_before" action="" method="POST" class="form-horizontal photo" @change="uploadImage" enctype="multipart/form-data">
                      <input type="hidden" name="visit_id" :value="current_visit.id">
                      <input type="hidden" name="type" value="before">
                      <input type="file" id="before" ref="beforeImage" name="photo" accept="image/*" capture="camera"/>
                      <label ref="beforeImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>
                <CCol>
                  <form id="upload_after" action="" method="POST" class="form-horizontal photo" @change="uploadImage" enctype="multipart/form-data">
                      <input type="hidden" name="visit_id" :value="current_visit.id">
                      <input type="hidden" name="type" value="after">
                      <input type="file" id="after" ref="afterImage" name="photo" accept="image/*" capture="camera"/>
                      <label ref="afterImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>
                <CCol>
                  <form id="upload_problem" action="" method="POST" class="form-horizontal photo" @change="uploadImage" enctype="multipart/form-data">
                      <input type="hidden" name="visit_id" :value="current_visit.id">
                      <input type="hidden" name="type" value="problem">
                      <input type="file" id="problem" ref="problemImage" name="photo" accept="image/*" capture="camera"/>
                      <label ref="problemImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>
              </CRow>
              <CRow v-if="more_pictures" style="font-size: 12px;">
                <CCol>
                  <br />
                  Más Fotografías
                  <br /><br />
                </CCol>
              </CRow>
              <CRow v-show="more_pictures" style="text-align: center; font-size: 12px;">
                <CCol>
                  <form id="additional_1_form" action="" method="POST" class="form-horizontal photo" @change="uploadImage" enctype="multipart/form-data">
                      <input type="file" id="additional_1" ref="additional1Image" name="photo" accept="image/*" capture="camera"/>
                      <label ref="additional1ImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>
                <CCol>
                  <form id="additional_2_form" action="" method="POST" class="form-horizontal photo" @change="uploadImage" enctype="multipart/form-data">
                      <input type="file" id="additional_2" ref="additional2Image" name="photo" accept="image/*" capture="camera"/>
                      <label ref="additional2ImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>
                <CCol>
                  <form id="additional_3_form" action="" method="POST" class="form-horizontal photo" @change="uploadImage" enctype="multipart/form-data">
                      <input type="file" id="additional_3" ref="additional3Image" name="photo" accept="image/*" capture="camera"/>
                      <label ref="additional3ImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>
              </CRow>
              <CRow v-if="!more_pictures">
                <CCol>
                  <br />
                  <div><CButton style="width: 100%" color="info" size="sm" @click="moreImages()">Más Fotografías</CButton></div>
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CCol md="12">
                  <CTextarea label="Comentarios" v-model="comments"></CTextarea>                  
                </CCol>
                <CCol md="12">
                  <CButton style="float: right;" color="info" size="sm" @click="sendComments()">Registrar Comentarios</CButton>
                </CCol>
              </CRow>    
              <hr />
              <CRow v-if="has_promotion">
                <CCol md="12">
                  <label for=""><strong style="color: red;">PROMOCIÓN ACTIVA</strong></label>
                </CCol>
                <CCol md="12">
                  <CRow>
                    <CCol md="12">
                      <label for=""><i>{{promotion}}</i></label>
                    </CCol>
                    <CCol md="12">
                      <div style="float:right; text-align: right;">
                        <span style="padding-bottom: 40px;">¿Tiene la promoción?</span><br />
                        <CButton color="info" :disabled="status_promotion != -1 && status_promotion == 1" size="sm" @click="hasPromotion(1)" style="margin-right: 10px; margin-top: 10px; width: 40px;">SI</CButton>
                        <CButton color="warning" :disabled="status_promotion != -1 && status_promotion == 0" size="sm" @click="hasPromotion(0)" style="width: 40px;margin-top: 10px; ">NO</CButton>
                      </div>
                    </CCol>                                
                  </CRow>
                </CCol>
                <CCol md="12">
                  <CTextarea label="Reporte de Promoción" v-model="comments_promotion"></CTextarea>                  
                </CCol>
                <CCol md="12">
                  <CButton style="float: right;" color="info" size="sm" @click="sendCommentPromotion()">Enviar Reporte</CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-show="has_exhibitions">
        <CCol sm="12" md="6">
          <CCard>
            <CCardHeader color="dark">
              <span style="font-size: 12px; color: #fff;" >
                <b>EXHIBICIONES ADICIONALES</b>
              </span>
            </CCardHeader>
            <CCardBody>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol style="text-align: center; font-size: 12px;">
                  Imagen 1
                </CCol>
                <CCol style="text-align: center; font-size: 12px;">
                  Imagen 2
                </CCol>
                <CCol style="text-align: center; font-size: 12px; margin-bottom: 10px;">
                  Imagen 3
                </CCol>
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol>
                  <form id="upload_additional_exhibition_1" action="" method="POST" class="form-horizontal photo" @change="uploadImageAdditional" enctype="multipart/form-data">
                      <input type="hidden" name="visit_id" :value="current_visit.id">
                      <input type="hidden" name="type" value="additional_exhibition_1">
                      <input type="file" id="additional_exhibition_1" ref="additionalExhibition1Image" name="photo" accept="image/*" capture="camera"/>
                      <label ref="additionalExhibition1ImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>       
                <CCol>
                  <form id="upload_additional_exhibition_2" action="" method="POST" class="form-horizontal photo" @change="uploadImageAdditional" enctype="multipart/form-data">
                      <input type="hidden" name="visit_id" :value="current_visit.id">
                      <input type="hidden" name="type" value="additional_exhibition_2">
                      <input type="file" id="additional_exhibition_2" ref="additionalExhibition2Image" name="photo" accept="image/*" capture="camera"/>
                      <label ref="additionalExhibition2ImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>      
                <CCol>
                  <form id="upload_additional_exhibition_3" action="" method="POST" class="form-horizontal photo" @change="uploadImageAdditional" enctype="multipart/form-data">
                      <input type="hidden" name="visit_id" :value="current_visit.id">
                      <input type="hidden" name="type" value="additional_exhibition_3">
                      <input type="file" id="additional_exhibition_3" ref="additionalExhibition3Image" name="photo" accept="image/*" capture="camera"/>
                      <label ref="additionalExhibition3ImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>                
              </CRow>             
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-show="has_product_promotion">
        <CCol sm="12" md="6">
          <CCard>
            <CCardHeader color="warning">
              <span style="font-size: 12px;">
                <b>PRECIOS Y PROMOCIONES</b>
              </span>
            </CCardHeader>
            <CCardBody>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol style="text-align: center; font-size: 12px;">
                  {{product_exists.code}} - {{product_exists.name}}
                  <br>
                  <br>
                </CCol>               
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">              
                <CCol style="text-align: center; font-size: 12px;">
                </CCol>
                <CCol v-show="has_price == 1" style="text-align: center; font-size: 12px;">
                  Imagen
                </CCol>
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">               
                <CCol>
                    <span style="padding-bottom: 40px;">¿Tiene Precio?</span><br />
                    <b>{{has_price == 0 ? "NO" : ((has_price == 1) ? "SI": "")}}</b><br />
                    <CButton v-if="has_price == -1"  color="info" size="sm" @click="additionalPromotionPrice(product_exists, 1)" style="margin-right: 10px; margin-top: 10px; width: 40px;">SI</CButton>
                    <CButton v-if="has_price == -1" color="warning" size="sm" @click="additionalPromotionPrice(product_exists, 0)" style="width: 40px; margin-top: 10px; ">NO</CButton>
                    <CButton v-if="has_price != -1" color="dark" size="sm" @click="additionalPromotionPrice(product_exists, -1)" style="margin-top: 10px; ">Cancelar</CButton>
                </CCol>                      
                <CCol v-show="has_price == 1">
                  <form id="upload_additional_promotion" action="" method="POST" class="form-horizontal photo" @change="uploadImageAdditionalPromotion" enctype="multipart/form-data">
                      <input type="hidden" name="visit_id" :value="current_visit.id">
                      <input type="hidden" name="type" value="additional_promotion">
                      <input type="file" id="additional_promotion" ref="additionalPromotionImage" name="photo" accept="image/*" capture="camera"/>
                      <label ref="additionalPromotionImagePreview" style="margin: 0px;" class="no-image"></label>
                  </form>
                </CCol>       
              </CRow>             
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" md="6">
          <CCard>
            <CCardHeader color="success">
              <span style="font-size: 12px;">
                <b>REPORTE DE LOS PRODUCTOS</b>
              </span>
            </CCardHeader>
            <CCardBody>
              <CRow v-for="(product, index) in products" :key="'product_'+index">
                <CCol sm="12" md="12" style="font-size: 12px;">
                  {{product.name}}<br />
                  <b>{{product.code}}</b>
                  <CRow v-if="product.has_stocks == -1">
                    <CCol>
                      <div style="float:right; text-align: right;">
                        <span style="padding-bottom: 40px;">¿Tiene inventarios?</span><br />
                        <CButton color="info" size="sm" @click="hasStocks(product, 1)" style="margin-right: 10px; margin-top: 10px; width: 40px;">SI</CButton>
                        <CButton color="warning" size="sm" @click="hasStocks(product, 0)" style="width: 40px;margin-top: 10px; ">NO</CButton>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow v-if="product.has_stocks == 1">
                    <CCol>
                      <CInput
                        label="Físico"
                        size="sm"
                        style="width: 80px; float: right;"
                        v-model="product.physical_stock"
                      />
                    </CCol>
                    <CCol>
                      <CInput
                        label="Lógico"
                        size="sm"
                        style="width: 80px; float: right;"
                        v-model="product.logic_stock"
                      />
                    </CCol>
                    <CCol>
                      <CInput
                        label="Frentes"
                        size="sm"
                        style="width: 80px; float: right;"
                        v-model="product.front_stock"
                      />
                    </CCol>
                  </CRow>
                  <CRow v-if="product.has_stocks == 0">
                    <CCol>
                      <br /><CTextarea label="" v-model="product.comments"></CTextarea>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div style="float:right" v-if="product.has_stocks == 0">
                        <CButton color="warning" size="sm" @click="sendProductComments(product)">Registrar Comentarios</CButton>
                      </div>
                      <div style="float:right" v-if="product.has_stocks == 1">
                        <CButton color="info" size="sm" @click="sendProduct(product)">Registrar Inventario</CButton>
                      </div>
                    </CCol>
                  </CRow>
                  <hr />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/operation';
import moment from 'moment';

export default {
  name: 'Home',
  components: {
    
  },
  data () {
    return {
      date: false,
      page: 'branchs',
      branch_id: 0,
      visit_id: 0,
      total_branchs: -1,
      has_exhibitions: 0,
      branchs: [],
      total_visits: -1,
      week_visits: 0,
      done_visits: 0,
      visits: [],
      has_product_promotion: false,
      current_visit: false,
      comments: '',
      promotion: "",
      has_promotion: false,
      status_promotion: -1,
      comments_promotion: "",
      has_price: -1,
      product_exists:{
        name:"",
        code:"",
        has_price: -1,
        id:"",
      },
      products: [],
      more_pictures: true,
      type: "today"
    }
  },
  mounted: async function() {
    this.loading();

    let param_type = await this.$route.params.type;
    let param_date = await this.$route.params.date;

    if(param_type !== undefined){
      this.type = param_type;
    }

    localStorage.setItem("today_visits", this.type);

    let date = moment().format("DD/MM/YYYY");
    if(param_date !== undefined){
      date = moment(param_date, "YYYY-MM-DD").format("DD/MM/YYYY");
    }
    
    let page = localStorage.getItem("page");
    let branch_id = localStorage.getItem("branch_id");
    let visit_id = localStorage.getItem("visit_id");

    let is_kam = localStorage.getItem("is_kam");

    if(is_kam == "true"){
      window.location.href = "/#/welcome"; return;
    }

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "true"){
      window.location.href = "/#/graphs/general"; return;
      window.location.href = "/#/control"; return;
    }

    if(date === undefined || date === null || page === undefined || page === null){
      this.page = "branchs";
      this.date = date;
      this.branch_id = 0;
      this.visit_id = 0;
    }
    else{
      this.page = page;
      this.date = date;
      this.branch_id = branch_id;
      this.visit_id = visit_id;
    }

    if(this.page == "visit"){
      if(visit_id === undefined || visit_id === null){
        this.page = "branchs";
        this.date = date;
        this.branch_id = 0;
        this.visit_id = 0;
      }
      else{
        this.startVisit(visit_id);
      }
    }

    if(this.page == "visits"){
      if(branch_id === undefined || branch_id === null){
        this.page = "branchs";
        this.date = date;
        this.branch_id = 0;
        this.visit_id = 0;
      }
      else{
        this.showVisit(branch_id);
      }
    }

    if(this.page == "branchs"){
      this.showBranchs();
    }
    this.setRandom();
  },
  methods: {
    async showBranchs () {
      this.loading();

      this.page = "branchs";
      
      let iso_date = moment(this.date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let response = false;

      if(this.type == "today"){
        response = await ws.getTodayBranchs(iso_date);
      }
      else{
        response = await ws.getWeekBranchs(iso_date);
      }

      if(response && response.type == "success"){
        this.branchs = response.data.branchs;
        this.total_branchs = response.data.total_branchs;

        localStorage.setItem("date", this.date);
        localStorage.setItem("page", this.page);
      }
      else{
        localStorage.removeItem("token");
        store.commit('clear');
        router.push("login");
      }

      let response2 = await ws.getWeekTotalVisits(); 

      if(response2.type == "success"){
        this.done_visits = response2.data.done_visits;
        this.week_visits = response2.data.week_visits;
      }

      this.loaded();
    },
    async checkin (branch_id) {
      let latitude = 0;
      let longitude = 0;

      let _this = this;

      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async function(position){
              latitude = position.coords.latitude;
              longitude = position.coords.longitude;
              
              await ws.setCheckIn(branch_id, latitude, longitude);

              _this.showVisit(branch_id);

          }, async function(err){
              console.warn(`ERROR(${err.code}): ${err.message}`);

              await ws.setCheckIn(branch_id, latitude, longitude);

              _this.showVisit(branch_id);
          });
      }
      else{
        alert("La aplicación no pudo acceder a la ubicación de tu dispositivo.");

        await ws.setCheckIn(branch_id, latitude, longitude);

        this.showVisit(branch_id);
      }
    },
    async showVisit (branch_id) {
      this.loading();

      let iso_date = moment(this.date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let response = false;

      if(this.type == "today"){
        response = await ws.getTodayVisits(branch_id, iso_date); 
      }
      else{
        response = await ws.getWeekVisits(branch_id, iso_date); 
      }
      
      if(response && response.type == "success"){
        let data = response.data;

        this.page = "visits";
        this.visits = data.visits;
        this.total_visits = data.total_visits;
        
        data.visits.forEach(async visit => {
            this.products = [];

            let response2 = await ws.getTotalVisitProducts(visit.id); 

            if(response2.type == "success"){
              let np = response2.data;

              visit.products = [];
              visit.np = np;              
            }
        });

        localStorage.setItem("page", this.page);
        localStorage.setItem("branch_id", branch_id);

        this.loaded();
      }
      else{
        localStorage.removeItem("token");
        store.commit('clear');
        router.push("login");
      }
    },
    async checkout (visit_id) {
      let response = await ws.setCheckOut(visit_id);

      if(response.type == "success"){
        let data = response.data;

        if(this.visits.length > 0){
          this.page = "visits";
          localStorage.setItem("page", this.page);
          
          this.showVisit(this.visits[0].branch_id);
        }
        else{
          this.page = "branchs";
          localStorage.setItem("page", this.page);

          this.showBranchs();
        }
      }

      this.showToast(response.type, response.message);
    },
    async backToBranchs () {
      this.page = "branchs";

      this.showBranchs();
    },
    async startVisit (visit_id) {
      this.loading();

      let response = await ws.getCurrentVisit(visit_id); 

      if(response.type == "success"){
        let data = response.data;
        let images = response.images;
                                                                                                                                                                                                                                                                                                         
        this.page = "visit";
        this.current_visit = data;

        localStorage.setItem("page", this.page);
        localStorage.setItem("visit_id", visit_id);

        this.comments = this.current_visit.comments;
        this.promotion = this.current_visit.promotion;
        this.has_promotion = this.current_visit.has_promotion;
        this.status_promotion = this.current_visit.status_promotion;
        this.comments_promotion = this.current_visit.comments_promotion;
        this.product_promotion_already_exists = (this.current_visit.id_additional_promotion > 0) ? 1 : 0;
        this.product_exists.has_price = this.current_visit.status_price_additional_promotion;
        this.product_exists.id = this.current_visit.id_additional_promotion;
        this.product_exists.name = this.current_visit.promotion_product_name;
        this.product_exists.code = this.current_visit.promotion_product_code;

        this.has_price = this.current_visit.status_price_additional_promotion;

        this.has_exhibitions = this.current_visit.has_exhibitions;
        this.has_product_promotion = this.current_visit.has_product_promotion;

        this.more_pictures = false;

        if(images.before_image !== undefined){
          let before_url = "https://s3.us-west-2.amazonaws.com/tumarketing3.com/uploads/visits/thumb_"+images.before_image.filename+"";
          this.$refs.beforeImagePreview.style.background = "no-repeat url('"+images.before_image.url+"')";
          this.$refs.beforeImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.beforeImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.after_image !== undefined){
          this.$refs.afterImagePreview.style.background = "no-repeat url('"+images.after_image.url+"')";
          this.$refs.afterImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.afterImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.problem_image !== undefined){
          let problem_url = "https://s3.us-west-2.amazonaws.com/tumarketing3.com/uploads/visits/thumb_"+images.problem_image.filename+"";
          this.$refs.problemImagePreview.style.background = "no-repeat url('"+images.problem_image.url+"')";
          this.$refs.problemImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.problemImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.additional_1_image !== undefined){
          this.more_pictures = true;
          let image_url = "https://s3.us-west-2.amazonaws.com/tumarketing3.com/uploads/visits/thumb_"+images.additional_1_image.filename+"";
          this.$refs.additional1ImagePreview.style.background = "no-repeat url('"+images.additional_1_image.url+"')";
          this.$refs.additional1ImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.additional1ImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.additional_2_image !== undefined){
          this.more_pictures = true;
          let image_url = "https://s3.us-west-2.amazonaws.com/tumarketing3.com/uploads/visits/thumb_"+images.additional_2_image.filename+"";
          this.$refs.additional2ImagePreview.style.background = "no-repeat url('"+images.additional_2_image.url+"')";
          this.$refs.additional2ImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.additional2ImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.additional_3_image !== undefined){
          this.more_pictures = true;
          let image_url = "https://s3.us-west-2.amazonaws.com/tumarketing3.com/uploads/visits/thumb_"+images.additional_3_image.filename+"";
          this.$refs.additional3ImagePreview.style.background = "no-repeat url('"+images.additional_3_image.url+"')";
          this.$refs.additional3ImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.additional3ImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.additional_exhibition_1 !== undefined){          
          this.$refs.additionalExhibition1ImagePreview.style.background = "no-repeat url('"+images.additional_exhibition_1.url+"')";
          this.$refs.additionalExhibition1ImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.additionalExhibition1ImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.additional_exhibition_2 !== undefined){
          this.$refs.additionalExhibition2ImagePreview.style.background = "no-repeat url('"+images.additional_exhibition_2.url+"')";
          this.$refs.additionalExhibition2ImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.additionalExhibition2ImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.additional_exhibition_3 !== undefined){
          this.$refs.additionalExhibition3ImagePreview.style.background = "no-repeat url('"+images.additional_exhibition_3.url+"')";
          this.$refs.additionalExhibition3ImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.additionalExhibition3ImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        if(images.additional_promotion !== undefined){
          this.$refs.additionalPromotionImagePreview.style.background = "no-repeat url('"+images.additional_promotion.url+"')";
          this.$refs.additionalPromotionImagePreview.style.backgroundSize = "84px 84px";
        }
        else{
          this.$refs.additionalPromotionImagePreview.style.backgroundImage = "url('https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png')";
        }

        data.product_ids.forEach(async product_id => {
            this.products = [];

            let response2 = await ws.getProductVisit(visit_id, product_id); 

            if(response2.type == "success"){
              let p = response2.data;

              if(p){
                p.has_stocks = -1;
                
                p.is_new = true;
                if(p.physical_stock > 0 || p.logic_stock > 0 || p.front_stock > 0){
                  p.is_new = false;
                  p.has_stocks = 1;
                }else{
                  if(p.comments !== undefined && p.comments !== null && p.comments.length > 0){
                    p.is_new = false;
                    p.has_stocks = 0;
                  }
                }
 
                this.products.push(p);
              }

              this.products.sort((a, b) => {
                  return a.id - b.id;
              });

              this.setRandom();

            }
        });

        this.loaded();
      }
      else{
        localStorage.removeItem("token");
        store.commit('clear');
        router.push("login");
      }
    },
    async backToVisits () {
      this.page = "visits";
    },
    async showProducts (visit) {
      let response = await ws.getVisitProducts(visit.id); 

      if(response.type == "success"){
        let data = response.data;

        visit.products = data;
      }
      else{
        localStorage.removeItem("token");
        store.commit('clear');
        router.push("login");
      }
    },
    async moreImages () {
      this.more_pictures = true;
    },
    async uploadImage (event) {
      this.loading();

      let responsive = this;

      let type = event.target.id;
      let file_input = event.target.files[0];

      let img_width = 600;

      const fileName = 'problem.jpg';
      let file = null;

      const reader = new FileReader();
      reader.readAsDataURL(file_input);

      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          let width = img_width;
          let height = width * img.height / img.width;

          const elem = document.createElement('canvas');
          elem.width = width;
          elem.height = height;
          const ctx = elem.getContext('2d');

          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob((blob) => {
              file = new File([blob], fileName, {
                  type: 'image/jpeg',
                  lastModified: Date.now()
              });

              var formData = new FormData();

              formData.append('photo', file);

              formData.append('type', type);
              formData.append('visit_id', this.current_visit.id);

              let xhr = new XMLHttpRequest();
              let api = 'https://api.tumarketing3.com';

              if(window.location.hostname == "localhost"){
                api = 'http://api.tumark.site';
              }

              if(document.domain == 'app.tmkt3.com' || document.domain == 'kam.tmkt3.com' || document.domain == 'adm.tmkt3.com' || document.domain == 'admin.tmkt3.com'){
                  api = "https://api.tmkt3.com";
              }

              xhr.open('POST', api+'/operation/upload-image');
              xhr.setRequestHeader("access-token", localStorage.getItem('token'));
              xhr.responseType = 'json';
              xhr.send(formData);

              xhr.onreadystatechange = function() {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                  let response = xhr.response;
                  myFunction(response);
                }
              };
          }, 'image/jpeg', 1);
        }, reader.onerror = error => console.log(error);
      };      

      var myFunction = function(response){
        responsive.showToast(response.type, response.message);
        
        if(response.type == "success"){
          let data = response.data;

          if(type == "before"){
            responsive.$refs.beforeImagePreview.style.background = "no-repeat url('"+data+"')";
          }

          if(type == "after"){
            responsive.$refs.afterImagePreview.style.background = "no-repeat url('"+data+"')";
          }

          if(type == "problem"){
            responsive.$refs.problemImagePreview.style.background = "no-repeat url('"+data+"')";
          }

          if(type == "additional_1"){
            responsive.$refs.additional1ImagePreview.style.background = "no-repeat url('"+data+"')";
          }

          if(type == "additional_2"){
            responsive.$refs.additional2ImagePreview.style.background = "no-repeat url('"+data+"')";
          }

          if(type == "additional_3"){
            responsive.$refs.additional3ImagePreview.style.background = "no-repeat url('"+data+"')";
          }

          responsive.$refs.beforeImagePreview.style.backgroundSize = "84px 84px";
          responsive.$refs.afterImagePreview.style.backgroundSize = "84px 84px";
          responsive.$refs.problemImagePreview.style.backgroundSize = "84px 84px";

          responsive.$refs.additional1ImagePreview.style.backgroundSize = "84px 84px";
          responsive.$refs.additional2ImagePreview.style.backgroundSize = "84px 84px";
          responsive.$refs.additional3ImagePreview.style.backgroundSize = "84px 84px";
        }

        responsive.loaded();
      }
    },
    async uploadImageAdditional (event) {
      this.loading();

      let responsive = this;

      let type = event.target.id;
      let file_input = event.target.files[0];

      let img_width = 600;

      const fileName = 'problem.jpg';
      let file = null;

      const reader = new FileReader();
      reader.readAsDataURL(file_input);

      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          let width = img_width;
          let height = width * img.height / img.width;

          const elem = document.createElement('canvas');
          elem.width = width;
          elem.height = height;
          const ctx = elem.getContext('2d');

          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob((blob) => {
              file = new File([blob], fileName, {
                  type: 'image/jpeg',
                  lastModified: Date.now()
              });

              var formData = new FormData();

              formData.append('photo', file);

              formData.append('type', type);
              formData.append('visit_id', this.current_visit.id);

              let xhr = new XMLHttpRequest();
              let api = 'https://api.tumarketing3.com';

              if(window.location.hostname == "localhost"){
                api = 'http://api.tumark.site';
              }

              if(document.domain == 'app.tmkt3.com' || document.domain == 'kam.tmkt3.com' || document.domain == 'adm.tmkt3.com' || document.domain == 'admin.tmkt3.com'){
                  api = "https://api.tmkt3.com";
              }

              xhr.open('POST', api+'/operation/upload-image-additional');
              xhr.setRequestHeader("access-token", localStorage.getItem('token'));
              xhr.responseType = 'json';
              xhr.send(formData);

              xhr.onreadystatechange = function() {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                  let response = xhr.response;
                  myFunction(response);
                }
              };
          }, 'image/jpeg', 1);
        }, reader.onerror = error => console.log(error);
      };      

      var myFunction = function(response){
        responsive.showToast(response.type, response.message);
        
        if(response.type == "success"){
          let data = response.data;

          if(type == "additional_exhibition_1"){
            responsive.$refs.additionalExhibition1ImagePreview.style.background = "no-repeat url('"+data+"')";
          }
          if(type == "additional_exhibition_2"){
            responsive.$refs.additionalExhibition2ImagePreview.style.background = "no-repeat url('"+data+"')";
          }
          if(type == "additional_exhibition_3"){
            responsive.$refs.additionalExhibition3ImagePreview.style.background = "no-repeat url('"+data+"')";
          }

          responsive.$refs.additionalExhibition1ImagePreview.style.backgroundSize = "84px 84px";
          responsive.$refs.additionalExhibition2ImagePreview.style.backgroundSize = "84px 84px";
          responsive.$refs.additionalExhibition3ImagePreview.style.backgroundSize = "84px 84px";
        }

        responsive.loaded();
      }
    },
    async uploadImageAdditionalPromotion (event) {
      this.loading();

      let responsive = this;

      let type = event.target.id;
      let file_input = event.target.files[0];

      let img_width = 600;

      const fileName = 'problem.jpg';
      let file = null;

      const reader = new FileReader();
      reader.readAsDataURL(file_input);

      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          let width = img_width;
          let height = width * img.height / img.width;

          const elem = document.createElement('canvas');
          elem.width = width;
          elem.height = height;
          const ctx = elem.getContext('2d');

          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob((blob) => {
              file = new File([blob], fileName, {
                  type: 'image/jpeg',
                  lastModified: Date.now()
              });

              var formData = new FormData();

              formData.append('photo', file);

              formData.append('type', type);
              formData.append('visit_id', this.current_visit.id);

              let xhr = new XMLHttpRequest();
              let api = 'https://api.tumarketing3.com';

              if(window.location.hostname == "localhost"){
                api = 'http://api.tumark.site';
              }

              if(document.domain == 'app.tmkt3.com' || document.domain == 'kam.tmkt3.com' || document.domain == 'adm.tmkt3.com' || document.domain == 'admin.tmkt3.com'){
                  api = "https://api.tmkt3.com";
              }

              xhr.open('POST', api+'/operation/upload-image-additional-promotion');
              xhr.setRequestHeader("access-token", localStorage.getItem('token'));
              xhr.responseType = 'json';
              xhr.send(formData);

              xhr.onreadystatechange = function() {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                  let response = xhr.response;
                  myFunction(response);
                }
              };
          }, 'image/jpeg', 1);
        }, reader.onerror = error => console.log(error);
      };      

      var myFunction = function(response){
        responsive.showToast(response.type, response.message);
        
        if(response.type == "success"){
          let data = response.data;

          if(type == "additional_promotion"){
            responsive.$refs.additionalPromotionImagePreview.style.background = "no-repeat url('"+data+"')";
          }
          responsive.$refs.additionalPromotionImagePreview.style.backgroundSize = "84px 84px";
        }

        responsive.loaded();
      }
    },
    async additionalPromotionPrice (product,status) {
      //Guardamos el status de la promocion
      this.loading();

      let response = await ws.sendPricePromotion(this.current_visit.id, product.id,status); 

      if(response.type == "success"){
        this.product_exists.has_price = status;
        this.has_price = status;
        this.showToast(response.type, response.message);
      }

      this.loaded();

    },
    async sendComments () {
      this.loading();

      let response = await ws.sendComments(this.current_visit.id, this.comments); 

      if(response.type == "success"){
        let data = response.data;

        this.showToast(response.type, response.message);
      }

      this.loaded();
    },    
    async hasStocks (product, status) {
      product.has_stocks = status;
    },
    async hasPromotion (status) {
      this.status_promotion = status;
      //Guardamos el status de la promocion
      this.loading();

      let response = await ws.sendStatusPromotion(this.current_visit.id, this.status_promotion); 

      if(response.type == "success"){
        this.showToast(response.type, response.message);
      }

      this.loaded();

    },
    async setRandom(){
      if(!this.product_promotion_already_exists){
        if(this.products.length > 0){
          let has_price = this.product_exists.has_price;
          this.product_exists = this.products[Math.floor(Math.random() * this.products.length)];
          this.product_exists.has_price = has_price;
          this.has_price = has_price;
        }
      }
    },
    async sendCommentPromotion () {
      this.loading();

      let response = await ws.sendCommentPromotion(this.current_visit.id, this.comments_promotion); 

      if(response.type == "success"){
        let data = response.data;

        this.showToast(response.type, response.message);
      }

      this.loaded();
    },
    async sendProduct (product) {
      if(product.physical_stock > 0 || product.logic_stock > 0 || product.front_stock > 0){
        this.loading();

        let data = {
          physical_stock: product.physical_stock,
          logic_stock: product.logic_stock,
          front_stock: product.front_stock
        }

        let response = await ws.sendProduct(this.current_visit.id, product.id, data); 

        if(response.type == "success"){
          let data = response.data;
        }

        this.loaded();

        this.showToast(response.type, response.message);
      }
      else{
        this.showToast("error", "Al menos uno de los valores de los inventarios debe ser mayor a cero.");
      }
    },
    async sendProductComments (product) {
      this.loading();
      
      let data = {
        comments: product.comments
      }

      let response = await ws.sendProductComments(this.current_visit.id, product.id, data); 

      if(response.type == "success"){
        let data = response.data;
      }

      this.loaded();

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
