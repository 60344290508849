import axios from 'axios'

export default {
    getTodayBranchs: (date) => date == undefined ? axios.post('operation/today-branchs') : axios.post('operation/'+date+'/today-branchs'),
    getWeekBranchs: (date) => date == undefined ? axios.post('operation/week-branchs') : axios.post('operation/'+date+'/week-branchs'),
    getTodayVisits: (branch_id, date) => date == undefined ? axios.post('operation/today-visits', {branch_id: branch_id}) : axios.post('operation/'+date+'/today-visits', {branch_id: branch_id}),
    getWeekVisits: (branch_id, date) => date == undefined ? axios.post('operation/week-visits', {branch_id: branch_id}) : axios.post('operation/'+date+'/week-visits', {branch_id: branch_id}),
    getWeekTotalVisits: () => axios.post('operation/week-total-visits'),
    setCheckIn: (branch_id, latitude, longitude) => axios.post('operation/checkin', {branch_id: branch_id, latitude: latitude, longitude: longitude}),
    setCheckOut: (visit_id) => axios.post('operation/checkout', {visit_id: visit_id}),
    getTotalVisitProducts: (visit_id) => axios.post('operation/total-visit-products', {visit_id: visit_id}),
    getVisitProducts: (visit_id) => axios.post('operation/visit-products', {visit_id: visit_id}),
    getCurrentVisit: (visit_id) => axios.post('operation/current-visit', {visit_id: visit_id}),
    getProductVisit: (visit_id, product_id) => axios.post('operation/product-visit', {visit_id: visit_id, product_id: product_id}),
    sendComments: (visit_id, comments) => axios.post('operation/send-comments', {visit_id: visit_id, comments: comments}),
    sendProduct: (visit_id, product_id, data) => axios.post('operation/send-products', {visit_id: visit_id, product_id: product_id, data: data}),
    sendProductComments: (visit_id, product_id, data) => axios.post('operation/send-product-comments', {visit_id: visit_id, product_id: product_id, data: data}),
    //Servicios de promocion
    sendStatusPromotion: (visit_id, status) => axios.post('operation/send-status-promotion', {visit_id: visit_id, status_promotion: status}),
    sendCommentPromotion: (visit_id, comment) => axios.post('operation/send-comment-promotion', {visit_id: visit_id, comments_promotion: comment}),    
    //Carga las visitas del dia enviado
    // chargeWeekVisits: (date) => axios.post('operation/charge-week-visits'),
    // chargeTodayVisits: (date) => axios.post('operation/charge-today-visits'),

    //Promocion producto
    sendPricePromotion: (visit_id,product_id, status) => axios.post('operation/send-price-product-promotion', {visit_id: visit_id, product_id: product_id,status_price_promotion: status}),
}